import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import axios from 'axios';

// Define the styles object
const styles = {
    container: {
        maxWidth: '500px',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        marginBottom: '15px',
        fontWeight: 'bold',
    },
    checkboxLabel: {
        marginBottom: '15px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '8px',
        marginTop: '5px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    checkbox: {
        marginRight: '10px',
    },
    button: {
        padding: '10px',
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    saveButton: {
        padding: '10px',
        backgroundColor: '#007bff', // A different color for distinction
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '10px', // Add some spacing
    },
    response: {
        marginTop: '20px',
        padding: '10px',
        backgroundColor: '#e9ecef',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
    },
    redirectingMessage: {
        marginTop: '20px',
        padding: '10px',
        backgroundColor: '#ffebcd',
        borderRadius: '4px',
        fontStyle: 'italic',
    },
    loader: {
        marginLeft: '10px',
        border: '4px solid #f3f3f3',
        borderRadius: '50%',
        borderTop: '4px solid #3498db',
        width: '16px',
        height: '16px',
        animation: 'spin 2s linear infinite',
    },
    successMessage: {
        marginTop: '10px',
        padding: '10px',
        backgroundColor: '#d4edda',
        borderRadius: '4px',
        color: '#155724',
    },
};

const SOCKET_SERVER_URL = 'https://ns-fcc-excellent-ciao.trycloudflare.com';

const BotRunner = () => {
    const [kimlikNo, setKimlikNo] = useState('');
    const [sifre, setSifre] = useState('');
    const [ihaleURL, setIhaleURL] = useState('');
    const [minPlus, setMinPlus] = useState('');
    const [maxLimit, setMaxLimit] = useState('');
    const [logs, setLogs] = useState([]);
    const [connectionStatus, setConnectionStatus] = useState('Sunucu Bağlantısı Yok');
    const [isLoading, setIsLoading] = useState(false); // For loader
    const [showSuccess, setShowSuccess] = useState(false); // For success message

    useEffect(() => {
        // Initialize Socket.IO client
        const socket = io(SOCKET_SERVER_URL, {
            transports: ['websocket'],  // Force WebSocket to avoid long-polling
        });

        // Connection established
        socket.on('connect', () => {
            console.log('Connected to WebSocket server');
            setConnectionStatus('Sunucu Bağlantısı Aktif');
        });

        // Connection error
        socket.on('connect_error', (err) => {
            console.error('WebSocket connection error:', err);
            setConnectionStatus('Sunucu Kapalı');
        });

        // Listen for log messages
        socket.on('log', (data) => {
            setLogs((prevLogs) => [...prevLogs, data.log]);
        });

        // Handle disconnection
        socket.on('disconnect', () => {
            console.log('Disconnected from WebSocket server');
            setConnectionStatus('Sunucu Bağlantısı Yok');
        });

        // Cleanup on component unmount
        return () => {
            socket.disconnect();
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Show loader
        setShowSuccess(false); // Reset success message
        try {
            const response = await axios.post(`${SOCKET_SERVER_URL}/run-bot`, {
                kimlik_no: kimlikNo,
                sifre: sifre,
                ihale_URL: ihaleURL,
                min_plus: parseFloat(minPlus),
                max_limit: parseFloat(maxLimit),
                remain_open: true
            });
            console.log(response.data.status);
            setLogs((prevLogs) => [...prevLogs, response.data.status]);
        } catch (error) {
            console.error('Error starting bot:', error);
            if (error.response) {
                setLogs((prevLogs) => [...prevLogs, error.response.data.error || 'Bir hata oluştu']);
            } else {
                setLogs((prevLogs) => [...prevLogs, 'Bir hata oluştu']);
            }
        } finally {
            setIsLoading(false); // Hide loader
        }
    };

    const saveLogs = () => {
        if (logs.length === 0) {
            alert('Kaydedilebilecek bir kayıt bulunamadı.');
            return;
        }

        // Convert the logs array into a single string with line breaks
        const logContent = logs.join('\n');

        // Create a Blob from the log content
        const blob = new Blob([logContent], { type: 'text/plain' });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;
        link.download = `bot_logs_${new Date().toISOString().slice(0, 19).replace(/[:T]/g, '-')}.txt`; // e.g., bot_logs_2023-10-09-15-30-00.txt

        // Append the link to the document body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up by removing the link and revoking the Blob URL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        // Optionally, show a success message
        setShowSuccess(true);

        // Hide the success message after a few seconds
        setTimeout(() => {
            setShowSuccess(false);
        }, 3000);
    };

    return (
        <div style={styles.container}>
            {/* Define keyframes for spin animation */}
            <style>
                {`
                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
                `}
            </style>

            <h2>Uyap Bot</h2>
            <p>Sunucu Durumu: {connectionStatus}</p>
            <form onSubmit={handleSubmit} style={styles.form}>
                <label style={styles.label}>
                    Kimlik No
                    <input 
                        type="text" 
                        value={kimlikNo} 
                        onChange={(e) => setKimlikNo(e.target.value)} 
                        placeholder="Kimlik No" 
                        required 
                        style={styles.input}
                    />
                </label>
                <label style={styles.label}>
                    Şifre
                    <input 
                        type="password" 
                        value={sifre} 
                        onChange={(e) => setSifre(e.target.value)} 
                        placeholder="Şifre" 
                        required 
                        style={styles.input}
                    />
                </label>
                <label style={styles.label}>
                    İhale URL
                    <input 
                        type="text" 
                        value={ihaleURL} 
                        onChange={(e) => setIhaleURL(e.target.value)} 
                        placeholder="İhale URL" 
                        required 
                        style={styles.input}
                    />
                </label>
                <label style={styles.label}>
                    Minimum period
                    <input 
                        type="number" 
                        value={minPlus} 
                        onChange={(e) => setMinPlus(e.target.value)} 
                        placeholder="Minimum period" 
                        required 
                        style={styles.input}
                    />
                </label>
                <label style={styles.label}>
                    Maximum Limit
                    <input 
                        type="number" 
                        value={maxLimit} 
                        onChange={(e) => setMaxLimit(e.target.value)} 
                        placeholder="Maximum Limit" 
                        required 
                        style={styles.input}
                    />
                </label>
                <button type="submit" style={styles.button}>
                    Bot'u Çalıştır
                    {isLoading && <div style={styles.loader}></div>}
                </button>
            </form>

            <div>
                <h3>Kayıt defteri</h3>
                <ul>
                    {logs.map((log, index) => (
                        <li key={index}>{log}</li>
                    ))}
                </ul>
                <button onClick={saveLogs} style={styles.saveButton}>
                    Kayıtları kaydet
                </button>
                {showSuccess && (
                    <div style={styles.successMessage}>
                        Kayıtlar başarı ile kaydedildi!
                    </div>
                )}
            </div>
        </div>
    );
};

export default BotRunner;
